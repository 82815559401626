.socialMediaLinks
{
    display: flex;
    /* justify-content: space-around; */
    margin-top: 16px;
}
.socialMediaType
{
    margin-right: 12px;
}
.socialMediaType a
{
    color: #757575;
}
.cardBanner.socialMedia 
{
    width: 320px;
}