.dashboardBannerContent {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
}

.dashboardBannerTitle {
    font-size: 32px !important;
    font-weight: 600 !important;
}

.pageDashboard .bannerImage {
    height: 140px;
    position: relative;
    bottom: -16px;
}

.appIdCard {
    background-color: #74b9ff !important;
}

.referralCard {
    background-color: #a29bfe !important;
}

.balanceCard {
    background-color: #55efc4 !important;
}

.debitAmount {
    background-color: #00d2d3 !important;
}

.amountWithUs {
    background-color: #fed330 !important;
}

.dummy {
    background-color: #4b6584 !important;
}

.dashboardCardTitle {
    font-size: 13px !important;
    padding: 12px 0px;
}

.dashboardCardValue {
    font-size: 20px !important;
    font-weight: 800 !important;
}

.dashboardCardIcon svg {
    font-size: 24px !important;
}

.dashboardCardIcon {
    padding-top: 24px !important;
}

.dashboardCards {
    height: 110px;
    margin-bottom: 16px;
    border-radius: 10px !important;
}

.whatsapp {
    background-color: #24CC63 !important;
}

.wechat {
    background-color: #00C10A;
}

.viber {
    background-color: #793BAA;
}

.facebook {
    background-color: #056ADF;
}

.instagram {
    background: linear-gradient(180deg, rgba(226, 132, 37, 1) 0%, rgba(198, 40, 141, 1) 100%);
}

.imo {
    background-color: #1A488B;
}

.socialMediaIcon img {
    width: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
    .dashboardBannerTitle {
        font-size: 24px !important;
    }

    .socialMediaBtn .btnIcon {
        display: none;
    }
}