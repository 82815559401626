.hoverCard
{
    display: none;
    padding: 16px;
    position: absolute;
    background-color: #ffffff;
    top: 36px;
    cursor: pointer;
    z-index: 9;
    right: 0px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.userAccount
{
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: end;
    width: 36px;
    float: right;
}
.userName p
{
    display: flex;
}
.userAccount:hover .hoverCard
{
    display: block;
}
.topBalance
{
    float: right;
    background-color: #407bff;
    padding: 6px;
    font-weight: 600;
    border-radius: 0px;
    color: #ffffff;
    /* margin-right: -28px; */
    font-size: 18px;
}
.headerItem
{
    margin-right: 16px;
}
.headerItemsCenter
{
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 16px;
}
.headerAppId
{
    font-size: 16px !important;
    color: #ffffff !important;
    font-weight: 600 !important;
}
.topWhatsappAction .socialMediaIcon
{
    float: left;
}
.topWhatsappAction a
{
    border-radius: 12px;
    color: #ffffff;
    text-decoration: none;
    padding-left: 3px;
    pointer-events: none;
    cursor: default;
}
@media only screen and (min-width: 300px) and (max-width: 1024px) 
{
    .topBalance
    {
        /* margin-right: -16px; */
    }
    .topBalance img
    {
        display: none;
    }
    .reloadBtn
    {
        margin-left: 6px !important;
    }
    .topWhatsappAction
    {
        position: absolute;
        left: 10px;
        bottom: 10px;
    }
    .pageHead
    {
        position: relative;
    }
    .headerItemsCenter
    {
        padding-bottom: 16px;
    }
}