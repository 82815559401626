.ruleValue {
    font-weight: 500;
}

.commonRuleTable th {
    font-weight: 600 !important;
}

.commonRuleTable th,
.commonRuleTable td {
    border: 1px solid #e0e0e0;
}

.rulesPage .pageTitle {
    margin-left: 0px !important;
    font-size: 28px !important;
    font-weight: 800;
    margin: 12px 0px;
}

.rulesPage .saperationSection {
    padding-top: 10px;
    padding-bottom: 10px;
}

.rulesPage .sectionTitle {
    margin-bottom: 2px;
}

.commonRules .bulletPoint {
    content: "";
    width: 12px;
    height: 5px;
    border-radius: 12px;
    background-color: #407bffad;
    position: absolute;
    top: 9px;
    left: -24px;
}

.commonRules p {
    position: relative;
    margin-left: 24px;
}

.landingV2 .heroSectionRules {
    background-image: url(../../assets/images/landingPage2V2/bannerBg.png);
    width: 100%;
    background-repeat: no-repeat;
}

.sectionTitleRules {
    color: #ffffff;
    font-size: 16px !important;
    font-weight: 700 !important;
    padding: 4px 0px 6px;
}

.rulesFont {
    color: #ffffff !important;
}



.pageTitleRules {
    font-size: 24px !important;
    font-weight: 700;
    color: #ffffff;
}