.card {
    width: 260px;
    height: 140px;
    color: #fff;
    cursor: pointer;
    /* perspective: 1000px; */
}

.card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.front {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(124deg, #407bff, #212121);
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    border-radius: 15px;
    overflow: hidden;
    z-index: 1;
    backface-visibility: hidden;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.map-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.1;
    z-index: -1;
}

.card-no {
    font-size: 20px;
    margin-top: 10px;
}

.cardLabel {
    font-size: 11px !important;
    padding-top: 20px;
    font-weight: 400 !important;
}

.cardValue {
    padding-top: 4px !important;
    font-weight: 600 !important;
}

.card-no p {
    margin: 0px;
}

.card-holder {
    font-size: 12px;
}

.name {
    font-size: 22px;
    margin-top: 0px;
}

.name p {
    margin: 0px;
}

.bar {
    background: #222;
    margin-left: -30px;
    margin-right: -30px;
    height: 60px;
    margin-top: 10px;
}

.card-cvv {
    margin-top: 20px;
}

.card-cvv div {
    flex: 1;
}

.card-cvv img {
    width: 100%;
    display: block;
    line-height: 0;
}

.card-cvv p {
    background: #fff;
    color: #000;
    font-size: 22px;
    padding: 10px 20px;
}

.card-text {
    margin-top: 30px;
    font-size: 14px;
}

.signature {
    margin-top: 30px;
}

.back {
    transform: rotateY(180deg);
}

/* .card:hover .card-inner {
  transform: rotateY(-180deg);
} */
.dNone {
    display: none !important;
}

.upiLogo {
    width: 60px;
}

.chipIcon {
    width: 30px;
}

.bankLogo {
    width: 30px;
}

.textRight {
    text-align: right !important;
}

.bankCardActions {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    left: 0px;
    top: 0px;
    z-index: 9;
    width: 290px;
    height: 170px;
    background-color: #212121d9;
    border-radius: 16px;
    display: none;
}
.primaryAccount
{
    color: #1abc9c !important;
    border: 2px solid #1abc9c !important;
}
.card-inner .front {
    position: relative;
}

.card-inner .front:hover .bankCardActions {
    display: flex;
}

.bankCardHeight {
    height: 220px;
}

.marB20 {
    margin-bottom: 20px !important;
}

@media only screen and (min-width: 1600px) and (max-width: 2000px) 
{
    .card 
    {
        width: 320px;
        height: 180px;
    }
    .bankCardHeight 
    {
        height: 280px;
    }
    .bankCardActions
    {
        width: 350px;
        height: 210px;
    }
    .cardLabel
    {
        padding-top: 28px;
    }
    .card-no p
    {
        font-size: 24px;
        margin-top: 25px;
    }
}