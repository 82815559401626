.walletBox
{
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center; 
}
.amountTitle
{
    font-size: 18px !important;
}
.amount
{
    font-weight: 700 !important;
    font-size: 42px !important;
}
.withDrawSection
{
    max-width: 1000px;
    margin: 30px auto;
}