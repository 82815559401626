.walletBox {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}

.amountTitle {
    font-size: 18px !important;
    color: #ffffff;
}

.amount {
    font-weight: 700 !important;
    font-size: 42px !important;
    color: #ffffff;
}

.paymentOption {
    /* display: flex; */
}

.paymentOption .commonListItem {
    flex-direction: column;
    /* float: left; */
    width: 16%;
}

.paymentScreens {
    height: 300px;
    margin: 24px 8px;
    border: 1px solid #212121;
}

.walletRecharge .bannerImage {
    width: 80px;
    height: 80px;
    margin-bottom: 12px;
}

@media only screen and (min-width: 280px) and (max-width: 600px) {
    .paymentOption .commonListItem {
        width: 50%;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 2000px) {
    .paymentScreens {
        height: 400px;
    }
}

/*Agents Team Css Starts Here*/
.agentsTeam .bannerImage {
    height: 120px;
    width: auto;
}

.agentsTeam .commonCenterCard {
    max-width: initial;
    margin: 24px;
}

.agentsTeam .commonCenterCard .sectionTitle {
    font-size: 20px !important;
    color: #ffffff;
}

.button-amount {
    color: #ffffff !important;
    border-color: #ffffff !important;
}


.inputLabel {
    color: #ffffff !important;
}

.input-border {
    border-color: #ffffff !important;
}

.helper-text {
    text-transform: initial;
    font-size: 1rem !important;
}

/*Agents Team Css Ends Here*/