.numbersPlayedTable
{
    border-radius: 0px !important;
}
.numbersPlayedTable th
{
    font-weight: 600 !important;
}
.numbersPlayedTable td
{
    text-align: left;
}