:root {
  --primaryColor: #171b27;
  --secondaryColor: #0adab9;
  --textTitle: #ffffff;
  --textPrimary: #bfc6d7;
  --textSecondary: #212121;
  --commonText: #BFC6D7;
}

.landingV2 {
  background-color: var(--primaryColor);
  height: 100%;
  overflow-y: scroll;
}

.landingV2 .heroSection {
  background-image: url(../../assets/images/landingPage2V2/bannerBg.png);
  width: 100%;
  height: 120%;
  background-repeat: no-repeat;
}

.landingV2 .btnRightSpacing {
  margin-right: 18px !important;
}

.landingV2 .topNavbar {
  display: flex;
  justify-content: space-between;
  padding: 32px 0px;
}

.landingV2 .socialBg {
  background-image: url(../../assets/images/landingPage2V2/bgsocial.png);

  width: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  text-align: center;
  background-position: center;
  height: 356px;
}

.landingV2 .rotatingImage {
  width: 100%;
  height: auto;
  animation: rotateOpacity 16s infinite linear;
}

.landingV2 .playType .count {
  background-color: var(--secondaryColor);
  color: var(--textSecondary);
  width: 42px;
  height: 42px;
  border-radius: 30px;
  font-size: 24px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.landingV2 .playType .mainTitle {
  font-size: 28px !important;
  font-weight: 500 !important;
}

.landingV2 .mainLogo {
  width: 180px;
}

.landingV2 .secondaryTitle {
  color: var(--secondaryColor);
  font-weight: 600;
  font-size: 30px;
  margin: 6px 0px;
}

.landingV2 .mainTitle {
  color: var(--textTitle);
  font-weight: 700;
  font-size: 55px;
  margin: 0px;
}

.landingV2 .commonText {
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  color: var(--commonText);
  margin: 18px auto;
}

.landingV2 .primaryBtn,
.landingV2 .primaryBtn:hover {
  background-color: var(--secondaryColor);
  color: var(--textSecondary);
}

.landingV2 .secondaryBtn,
.landingV2 .secondaryBtn:hover {
  background-color: transparent !important;
  color: var(--secondaryColor) !important;
  border: 1px solid var(--secondaryColor) !important;
  border-radius: 4px;
}

.landingV2 .contentCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.landingV2 .text-center {
  text-align: center;
}

.landingV2 .linkNav {
  color: var(--textTitle) !important;
  text-decoration: none !important;
  padding: 0px 24px;
  font-weight: 600 !important;
  font-size: 18px !important;
}

@keyframes rotateOpacity {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    transform: rotate(180deg);
    opacity: 0.3;
  }

  75% {
    transform: rotate(270deg);
    opacity: 0.6;
    /* Reduced opacity at 270 degrees */
  }

  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

/* Outer container for scrolling content */
.landingV2 .scroll-container {
  width: 95%;
  /* Full width */
  height: 100%;
  /* Adjust height as needed */
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

/* Inner scrolling content */
.landingV2 .scroll-content {
  display: flex;
  /* Flexbox for horizontal layout */
  animation: scroll-left 10s linear infinite;
  /* Adjust speed */
  width: 100%;
  height: 100%;
}

/* Columns */
.landingV2 .column {
  width: 150px;
  /* Adjust column width */
  margin: 10px;
  /* Spacing between columns */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Top and bottom cards */
.landingV2 .topcard,
.bottomcard {
  width: 100%;
  height: 80px;
  /* Adjust card height */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9ecef;
  /* Optional styling */
  margin: 5px 0;
  /* Spacing between cards */
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
}

/* Pause scrolling on hover */
.landingV2 .scroll-container:hover .scroll-content {
  animation-play-state: paused;
}

/* Horizontal scrolling animation */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.landingV2 .footerSectionTop {
  padding: 42px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landingV2 .footerSectionBottom {
  border-top: 1px solid #bfc6d7;
  text-align: center;
  padding: 32px;
  color: var(--commonText);
  font-weight: 600;
}

.landingV2 .footerLogo {
  max-width: 280px;
}

.landingV2 .footerSectionTop .linkNav {
  color: var(--commonText) !important;
  text-decoration: underline !important;
  margin: 40px 0px;
}

.landingV2 .boxSpacing {
  padding: 60px 0px;
}

.landingV2 .mainPart {
  display: flex;
  flex-direction: row;
  height: 220px;
  margin: 12px;
  width: 100%;
}

.landingV2 .playType {
  margin-top: 24px;
}

.landingV2 .playTypeImage {
  width: 50%;
  float: left;
  margin: 50px 0px 80px;
}

.landingV2 .imgPart {
  background-color: #ffffff;
  max-width: 360px;
  margin: 0px 9px;
  border-radius: 8px;
}

.landingV2 .imgPart img {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.landingV2 .stepDescription {
  width: 60%;
  text-align: center;
  margin: 32px auto;
}

.landingV2 .wh100 {
  width: 100%;
  height: 100%;
}

.landingV2 .socialBg h2 {
  font-size: 36px;
}

.landingV2 .referEarn {
  max-width: 360px;
  margin: 0 auto !important;
  padding: 12px 24px !important;
  background-color: var(--primaryColor) !important;
  box-shadow: none !important;
  color: var(--secondaryColor) !important;
}

.landingV2 .footerInfo {
  width: 50%;
  text-align: center;
  margin: 50px 0px !important;
}

.landingV2 .footerLinksPart {
  margin: 32px 0px;
}

.landingV2 .topNavLinks {
  display: flex;
}

.landingV2 #google_translate_element {
  position: initial;
  width: 170px !important;
}

.landingV2 #google_translate_element .goog-te-gadget-simple {
  background-color: transparent !important;
  border: 0px !important;
}

.landingV2 #google_translate_element .goog-te-gadget-simple span {
  color: var(--textTitle) !important;
  font-size: 18px;
  font-family: roboto !important;
  font-weight: 500;
}

.topWhatsappActionLanding
{

}
.topWhatsappActionLanding .socialMediaIcon
{
    float: left;
}
.topWhatsappActionLanding a
{
    border-radius: 12px;
    color: #ffffff;
    text-decoration: none;
    padding-left: 3px;
    pointer-events: none;
    cursor: default;
}

@media only screen and (min-width: 280px) and (max-width: 1024px) {
  .landingV2 .topNavbar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
  }

  .landingV2 .mainTitle {
    font-size: 30px;
  }

  .landingV2 .secondaryTitle {
    font-size: 20px;
  }

  .landingV2 .heroSection {
    height: 100%;
  }

  .landingV2 .stepDescription {
    width: auto;
  }

  .landingV2 .scroll-container {
    width: 100%;
  }

  .landingV2 .footerInfo {
    width: auto;
    margin-bottom: 12px !important;
  }

  .landingV2 .playType {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landingV2 .playTypeBody {
    display: flex;
    justify-content: center;
  }

  .landingV2 .footerLinksPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .landingV2 .footerSectionTop .linkNav {
    margin: 8px 0px;
  }

  .landingV2 .mainLogo {
    margin-bottom: 12px;
  }

  .landingV2 .topNavLinks {
    padding: 0px 0px 24px;
    display: flex;
  }

  .landingV2 .heroSectionContainer {
    height: 100% !important;
  }

  /* .landingV2 .heroSectionContent {
    margin-top: 32px;
  } */

  .landingV2 .rotatingImage {
    width: 70%;
  }

  .landingV2 .linkNav,
  .landingV2 #google_translate_element .goog-te-gadget-simple span {
    font-size: 12px !important;
    padding: 0px 6px !important;
  }

  .landingV2 #google_translate_element {
    width: 120px !important;
    margin-right: 0px;
  }

  .landingV2 #google_translate_element .goog-te-gadget-simple span {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 280px) and (max-width: 600px) {
  .landingV2 .rotatingImage {
    width: 100%;
  }

  .landingV2 .btnRightSpacing {
    margin-right: 4px !important;
  }
  .landingV2 .socialBg
  {
    height: 620px;
    background-size: cover;
  }
  .landingV2 .socialBg .contentCenter
  {
    flex-direction: initial;
  }
}