.leftMenuSection
{
    width: 280px;
    transition: all 0.3s ease-in-out;
}
.leftMenuSection.toggled-class
{
    width: 80px;
    transition: all 0.3s ease-in-out;
}
.leftMenuSection.toggled-class .brandNavigationIcon 
{
    display: flex;
    justify-content: center;
}
.portalLogoIcon
{
    display: none;
}
.leftMenuSection.toggled-class .brandNavigationTitle
{
    display: none;
}
.leftMenuSection.toggled-class .portalLogo
{
    display: none;
}
.leftMenuSection.toggled-class .portalLogoIcon
{
    display: block;
    height: 40px;
    padding: 16px 8px 0px;
}
.leftMenuAction
{
    position: absolute;
    /* right: -60px; */
    top: 16px;
    background-color: transparent;
    border: 0px;
    color: #212121;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    padding: 0px;
    cursor: pointer;
}
.leftMenuAction svg
{
    width: 32px;
    height: 32px;
}
.leftMenuSection.toggled-class .brandPart
{
    display: flex;
    justify-content: center;
}
.leftNavItem
{
    height: 48px;
}
.leftMenuSection.toggled-class .leftMenuAction
{
    transform: rotate(180deg);
}
@media only screen and (min-width: 300px) and (max-width: 1024px) 
{
    .leftMenuSection.toggled-class .brandNavigationTitle
    {
        display: block;
    }
    .leftMenuSection.toggled-class .portalLogo
    {
        display: block;
    }
    .leftMenuSection.toggled-class .portalLogoIcon
    {
        display: none;
    }
    .leftMenuSection.toggled-class .brandPart
    {
        justify-content: left;
    }
    .pageLeft
    {
        position: absolute;
        z-index: 99;
        background-color: #fff;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
    .leftMenuSection
    {
        margin-left: -280px;
    }
    .leftMenuSection.toggled-class
    {
        margin-left: 0px;
        width: 280px;
    }
    .leftMenuSection.toggled-class .leftMenuAction
    {
        right: 10px;
    }
    .leftMenuAction.toggled-class
    {
        right: 10px;
    }
    .pageTitle
    {
        margin-left: 50px !important;
    }
    .leftMenuAction
    {
        right: -40px !important;
    }
    .leftMenuSection .leftMenuAction
    {
        transform: rotate(180deg);
    }
    .leftMenuSection.toggled-class .leftMenuAction
    {
        transform: rotate(0deg);
    }
}