.mediaCard
{
    height: 200px;
}
.mangageMediaCard .actionInfo
{
    display: block;
    padding: 0px 16px;
}
.mangageMediaCard .cardTitle
{
    line-height: 40px !important;
}