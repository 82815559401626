.primaryColor {
  /* color: #407bff; */
  /* color: #336aea; */
}

.primaryFillBtn {
  /* background-color: #333262 !important; */
  color: #ffffff !important;
  padding: 8px !important;
}

.primaryOverlayIconBtn {
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
  margin: 0px 12px !important;
}

.whatsappBtn {
  background-color: #29ad17 !important;
  color: #ffffff !important;
  padding: 6px 8px;
  border-radius: 4px;
  margin: 12px 8px !important;
}

.socialMediaBtn {
  color: #ffffff !important;
  padding: 6px 2px;
  border-radius: 0px;
  margin: 12px 8px !important;
}

.commonLabelValue {
  padding: 4px 0px;
  display: flex;
}
.commonLabelValue.blockContent
{
  flex-direction: column;
}

.commonInputTopSpacing {
  margin-top: 16px !important;
}

.commonSelect {
  height: 42px;
}

.commonSelect fieldset {
  border: 0px;
  border-bottom: 1px solid #dddddd;
  border-radius: 0px !important;
}

.commonSelect .MuiSelect-select {
  padding: 0px 60px 0px 0px;
}

.commonLabelValue.nextLabelValue {
  display: block;
}

.commonLabelValue.nextLabelValue .commonValue {
  justify-content: left;
}

.commonLabel {
  color: #757575;
  font-size: 12px !important;
  font-weight: 500 !important;
  display: flex;
  flex-grow: 1;
  justify-content: left;
}

.commonValue {
  font-size: 14px !important;
  font-weight: 500 !important;
  display: flex;
  justify-content: right;
}
.commonLabelValue.blockContent .commonValue
{
  justify-content: left;
}
.commonBgCard {
  padding: 16px;
  /* background-color: #407bff26; */
  background-color: #232732;
  border-radius: 10px;
}

.commonBgCard .MuiInputBase-input {
  color: #ffffff;
  font-weight: 600;
}

.cardBanner {
  width: 200px;
  text-align: center;
  margin: 0 auto;
}

.bannerImage {
  height: 180px;
}

.bannerImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.commonImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bannerTitle {
  font-size: 18px !important;
  font-weight: 600 !important;
  padding-bottom: 8px;
}

.sectionTitle {
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 4px 0px 6px;
}

.normalText {
  font-size: 14px !important;
  color: #000000 !important;
}

.saperatorSpacing {
  margin-bottom: 12px;
}

.saperatorBorder {
  border-top: 1px solid #cccccc;
  padding-top: 12px;
  margin-top: 12px !important;
}

html,
body {
  height: 100%;
  overflow-y: hidden;
}

body {
  background-color: #f7fbfe;
}

::-webkit-scrollbar-thumb {
  background-color: #757575 !important;
  border-radius: 6px !important;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 10px !important;
}

.height100 {
  height: 100%;
}

.width100 {
  width: 100%;
}

.commonCard {
  -webkit-box-shadow: 0px 3px 12px 0px rgb(121 112 112 / 17%);
  -moz-box-shadow: 0px 3px 12px 0px rgb(121 112 112 / 17%);
  box-shadow: 0px 3px 12px 0px rgb(121 112 112 / 17%);
  border: 1px solid #eeeeee;
  border-radius: 0px;
}

.commonCard .MuiPaper-root {
  box-shadow: none;
  border-radius: 8px;
}

.cardBottomActions {
  margin: 0px 16px 8px !important;
  border-top: 1px solid #cccccc;
}

.iconActionBtns {
  cursor: pointer;
  text-align: center;
}

.iconActionBtns .icon {
  font-size: 12px;
  position: relative;
  top: 6px;
  padding-right: 3px;
}

.iconActionBtns .icon svg {
  width: 20px;
}

.commonCenterCard {
  max-width: 450px;
  margin: 24px auto;
}

.floatL {
  float: left;
}

.floatR {
  float: right;
}

.textCenter {
  text-align: center !important;
}

.floatRightBtn {
  float: right;
  padding: 20px 0px !important;
}

.floatRightBtn .primaryFillBtn {
  width: 200px;
}

.boxRight {
  display: flex;
  justify-content: end;
}

.boxLeft {
  display: flex;
  justify-content: start;
}

.switchTopRight {
  padding-top: 0px !important;
  position: relative;
  top: 10px;
}

.errorMessage {
  color: #f00 !important;
  font-weight: 400 !important;
}

.commonFormField {
  margin-bottom: 18px;
}

.verticalCenter {
  display: flex;
  align-items: center;
}

.commonBottomSpacing {
  margin-bottom: 24px !important;
}

.pageTitle {
  font-size: 24px !important;
  font-weight: 700;
  margin-left: 36px !important;
}

.pageMain {
  height: 100%;
  display: flex;
}

.pageView {
  height: 100%;
  /* padding: 0px 32px; */
  padding: 0px 0px;
}

.pageViewHead {
  padding: 0px;
}

/* .pageHead {
  padding: 16px 32px;
} */

.userPhoto {
  float: left;
  width: 36px;
  display: flex;
}

.userPhoto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.userName {
  font-size: 16px;
  font-weight: 600;
  flex-grow: 1;
  padding-left: 8px;
}

.pageLeft {
  /* background-color: #407bff26; */
  height: 100%;
  /* min-width: 280px; */
  position: relative;
}

.brandPart {
  height: 60px;
}

.portalLogo {
  height: 40px;
  padding: 16px 16px 0px;
}

.brandNavigation {
  padding-top: 24px;
  height: calc(100% - 86px);
  overflow-y: auto;
}

.brandNavigationIcon {
  min-width: 40px !important;
  color: #ffffff !important;
}

.brandNavigationTitle {
  color: #ffffff;
  font-weight: 600 !important;
}

.pageRight {
  flex-grow: 1;
  height: 100%;
}

.pageViewBody {
  background-color: #ffffff;
  border-radius: 0px;
  height: calc(100% - 160px);
  padding: 24px;
  overflow-y: auto;
}

.pageViewFooter {
  height: auto;
  padding: 12px 0px;
  text-align: center;
  /* background-color: #212121; */
}

.pageViewFooter p {
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
}

.verificationStatus {
  width: 24px;
  height: 24px;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verificationStatus svg {
  font-size: 18px;
}

.verified {
  background-color: #1abc9c;
}

.approved {
  background-color: #0bf072;
}

.pending {
  background-color: #f1c40f;
}

.declined {
  background-color: #e74c3c;
}

.greenText {
  color: #1abc9c;
}

.editAction {
  color: #407bff;
}

.deleteAction {
  color: #e74c3c;
}

.cardTitle {
  margin: 0px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}

.cardSpacing {
  padding: 16px 24px;
  border-bottom: 1px solid #eeeeee;
}

.textEclipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.commonSwitch {
  position: relative;
  margin: 0px !important;
  top: -8px;
}

.commonDatePicker {
  width: 100%;
}

.commonDatePickerTopSpacing {
  margin-top: -8px !important;
}

.commonUL {
  margin: 0px;
  padding: 0px;
  padding-left: 18px;
}

.commonNotePoints {
  font-size: 13px;
}

.commonCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textDecorationNone {
  text-decoration: none;
}

.padB0 {
  padding-bottom: 0px !important;
}

/*Common Modal Css Starts Here*/
.commonModal .commonModalBox {
  max-width: 440px;
}

.commonModal .commonModalBox.commonExtraModalBox {
  width: 800px;
  max-width: 800px !important;
}



.modalTitle {
  font-size: 19px !important;
  font-weight: 600 !important;
}

.modalClose svg {
  font-size: 16px;
  float: right;
  padding-top: 4px;
  color: #757575;
  cursor: pointer;
}

/*Common Modal Css Ends Here*/
/*Custom Scroll Bar Css Starts Here*/
.commonScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #F5F5F5;
}

.commonScroll::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

.commonScroll::-webkit-scrollbar-thumb {
  border-radius: 8xpx;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #407bff26;
}

/*Custom Scroll Bar Css Ends Here*/
/*DataTable Css Starts Here*/
.MuiDataGrid-columnHeaders {
  background-color: #f4f4f4;
}

.MuiDataGrid-cellContent {
  font-size: 12px;
  color: #212121;
}

/*DataTable Css Ends Here*/
/*Common Basic Table Css Starts Here*/
.commonBasicTable {
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%) !important;
  box-shadow: none !important;
}

/*Common Basic Table Css Ends Here*/
/*Account Pages Css Starts Here*/
.accountTitle {
  font-size: 28px !important;
  padding-bottom: 18px;
  font-weight: 500 !important;
}

.accountMain {
  background-image: url("../src/assets/images/login/loginBg1.png");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.accountMain .pageView {
  flex-grow: 1;
  padding: 32px;
  height: calc(100% - 64px);
}

.accountBodyRight {
  display: flex;
  align-items: center;
}

.accountBodyRight .commonCard {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
}

.accountBodyRight .commonCard .MuiPaper-root {
  padding: 16px;
}

/*Account Pages Css Ends Here*/
.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

/*Page Loader Css Starts Here*/
.page_loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #21212160;
  z-index: 99999;
}

.page_loader .loaderIcon {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -25px;
  margin-left: -25px;
}

/*Page Loader Css Ends Here*/

/*Common List Avtar Css Starts Here*/
.listAvtar {
  background-color: transparent !important;
  border: 0px;
  width: 100px !important;
  height: 70px !important;
  border-radius: 0px !important;
}

.listAvtarTitle span {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin: 0px;
  display: none;
}

/*Common List Avtar Css Ends Here*/
.sectionBtn {
  height: 56px;
  width: 100%;
}

.noteSummaryCard {
  height: 150px;
}

/*Single Page Layout Css Starts Here*/
.singlePageLayout .pageHead {
  padding: 0px;
  margin-bottom: 30px;
}

.singlePageLayout .pageViewBody {
  height: calc(100% - 190px);
}

.singlePageLayout .customContainer {
  padding: 0px !important;
  width: 100%;
  max-width: 100%;
  margin: 0px 40px;
}

.singlePageLayout .pageTitle {
  margin-left: 0px !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  line-height: 62px;
}

.singlePageLayout .landingPageNavBar .pageTitle {
  display: none;
}

.customTopSpacing {
  padding-top: 12px !important;
}

.singlePageLayoutNavbarActions {
  display: flex;
  justify-content: end;
}

/*Single Page Layout Css Ends Here*/
.pageConstructionPage {
  margin: 0 auto;
}

.contentCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*Responsive Css Starts Here*/
/* @media only screen and (max-width: 600px)  */
@media only screen and (min-width: 280px) and (max-width: 600px) {
  .pageView {
    padding: 0px;
  }

  .commonTabsHead .MuiTabs-flexContainer {
    max-width: 360px;
    overflow-x: scroll;
  }

  .commonModal .commonModalBox {
    width: 95%;
  }

  .responsiveHiddenPart {
    display: none;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .pageView {
    padding: 0px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .pageHead {
    padding: 16px;
  }

  .sectionBtn {
    height: auto;
    width: auto;
  }

  .noteSummaryCard {
    height: auto;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 2000px) {
  .loginBackground {
    width: 950px !important;
  }

  .highResolutionResponsiveCenter {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
  }
}

@media only screen and (min-width: 2001px) {}

/*Responsive Css Ends Here*/
.acceptedPayments .listAvtar img {
  border-radius: 0px !important;
}

.acceptedPayments .listAvtarTitle span {
  display: block;
}

.posRelative {
  position: relative;
}

/*Landing Page Css Starts Here*/
.whiteText {
  color: #ffffff;
}

.saperationSection {
  padding-top: 30px;
  padding-bottom: 30px;
}

.saperationSectionMedium {
  padding-top: 10px;
  padding-bottom: 10px;
}

.noDataFound {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 42px;
}

.noDataFoundLabel {
  font-size: 24px !important;
  font-weight: 500 !important;
  text-transform: initial;
}

.noDataFoundPreview {
  max-width: 320px;
}

.currencySymbol {
  padding-right: 3px;
  font-size: 14px;
}

.selectPagenation {
  border-bottom: 1px solid #212121;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: end !important;
  margin-right: 20px;
}

.selectPagenation .commonSelect {
  margin-right: 30px;
  margin-top: -8px;
}

.btnIcon {
  padding-right: 8px;
}

.bulletPoint {
  font-size: 13px;
  line-height: 28px;
  font-weight: 400;
}

/*Common Modal Popup Scroll*/
.commonModal {
  /* height: 100%;
  max-height: 780px; */
}

.commonModal .commonModalBox {
  height: calc(100% - 40px);
  margin: 40px auto;
  /* height: calc(100% - 40px);
  overflow-y: auto;
  max-height: 400px; */
}

.commonModalHead {
  border-bottom: 1px solid #cccccc;
  background-color: #ffffff;
  padding: 0px 24px 12px;
  /* padding: 12px 24px;
  height: 30px; */
}

.commonModalBoxInner {
  /* height: 100%;
  padding: 0px;
  overflow-y: hidden !important; */
}

.commonModalBoxInner {
  height: 100%;
}

.commonModalBody {
  max-height: calc(100% - 180px);
  overflow-y: scroll;
  background-color: #ffffff;
  padding: 24px 24px;
  /* height: calc(100% - 160px); */
  /* height: 70%;
    max-height: 600px;
    overflow-y: auto;
    padding: 32px 24px; */
}

.commonFooter {
  /* margin-top: 0px;
  padding: 12px 24px;
  height: 40px; */
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #cccccc;
  background-color: #ffffff;
  padding: 12px 24px;
}

.boxTopSpacing {
  margin-top: 16px !important;
}

.badgeContent {
  position: absolute;
  top: 12px;
  background-color: #15b700;
  float: right;
  right: 60px;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 12px;
}

/*Hover Card Actions Css Starts Here*/
.commonHoverCard {
  position: relative;
}

.commonHoverCardActions {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  left: 0px;
  top: 0px;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: #212121d9;
  border-radius: 8px;
  display: none;
}

.commonHoverCard:hover .commonHoverCardActions {
  display: flex;
}

.cardActionBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #407bff;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabSpacing .MuiTabPanel-root {
  padding: 16px 0px;
}

.fs24 {
  font-size: 24px !important;
}

.padT24 {
  padding-top: 24px !important;
}

/*Hover Card Actions Css Ends Here*/
/*Responsive Table Css Starts Here*/
.responsiveTable table {
  border: 1px solid #ccc;
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.responsiveTable table tr {
  border: 1px solid #ddd;
  padding: 5px;
}

.responsiveTable table th,
.responsiveTable table td {
  padding: 10px;
  text-align: left;
}

.MuiButton-root .MuiTouchRipple-root {
  display: none;
}

.responsiveTable .tableFixHead {
  overflow: auto;
  height: 100px;
}

.responsiveTable .tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
.responsiveTable table {
  border-collapse: collapse;
  width: 100%;
}

.responsiveTable th,
.responsiveTable td {
  padding: 8px 16px;
}

.responsiveTable th {
  background: #eee;
}

.alertTitle {
  font-weight: 700 !important;
  color: #212121;
  font-size: 19px !important;
  margin-bottom: 18px !important;
}

.commonValue1 {
  color: #212121 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

@media screen and (max-width: 1200px) {

  .responsiveTable table {
    border: 0;
  }

  .responsiveTable table thead {
    display: none;
  }

  .responsiveTable table tr {
    margin-bottom: 10px;
    display: block;
    border-bottom: 2px solid #ddd;
  }

  .responsiveTable table td {
    display: block;
    text-align: right;
    font-size: 13px;
    border-bottom: 1px border #ccc;
  }

  .responsiveTable table td:last-child {
    border-bottom: 0;
  }

  .responsiveTable table td:before {
    content: attr(data-label);
    float: left;
    text-transform: capitalize;
    font-weight: bold;
  }
}

/*Responsive Table Css Ends Here*/
/*Backup Classes*/
/* commonDatePickerTopSpacing
errorMessage 
commonHoverCard
commonHoverCardActions*/
/*Latest UI Starts Here*/
.primaryFillBtn,
.primaryLineBtn {
  transform: skew(-18deg);
  border-radius: 0px !important;
}

.primaryFillBtn span,
.primaryLineBtn span {
  transform: skew(18deg);
}

.pageTitle {
  color: #ffffff !important;
}

.pageHead {
  background-color: #171b27 !important;
  padding: 16px 16px 16px 0px;
}

.navBtn {
  background-color: #333262 !important;
  color: #ffffff !important;
}

.topBalance img {
  width: 20px;
  margin-right: 6px;
  position: relative;
  top: 3px;
}

.pageLeft {
  background-color: #171b27 !important;
}

.leftMenuAction svg {
  color: #ffffff;
}

.leftMenuAction {
  right: -30px;
}

.brandPart {
  height: 70px !important;
}

.brandNavigation {
  padding-top: 0px;
  height: calc(100% - 70px);
}

.activeMenuItem {
  background-color: #333262 !important;
  margin: 0px 12px !important;
}

.commonBgCard {
  /* background-image: url(assets/images/common/bgCard.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
}

.commonBgColorCard {
  background-image: none;
  background-color: #f3f2f8;
}

.whiteColor {
  color: #ffffff !important;
}

.marR8 {
  margin-right: 8px;
}

.padB0 {
  padding-bottom: 0px !important;
}

.padL0 {
  padding-left: 0px !important;
}

.customIcon {
  float: left;
}

.landingPageNavBar {
  background-color: #171b27
}

.pageViewFooter {
  background-color: #171b27
}

.announcementsSection {
  background-color: #171b27
}

.landingPageFooter {
  background-color: #333262 !important;
}

.primaryFillBtn {
  background-color: #333262 !important;
}

.primaryLineBtn {
  border: 2px solid #333262 !important;
  color: #333262 !important;
}

.Mui-selected {
  color: #333262 !important;
}

.MuiTabs-indicator {
  background-color: #333262 !important;
}

.primaryColor {
  color: #171b27 !important;
}

.primaryColorAction {
  color: #333262 !important;
}

.bannerTitle {
  color: #171b27
}

.width90 {
  width: 90% !important;
  margin: 12px auto !important;
}

.leftMenuSection.toggled-class .activeMenuItem {
  padding: 5px;
}

.landingPage .socialMediaType a {
  color: #ffffff;
}

.removeRowBtn .primaryColorAction {
  color: #e74c3c !important;
}

.responsivecommonModalBody {
  padding: 0px;
}

/* .eventsSection
{
  background-image: url(assets/images/landingPage/eventsBg.png);

} */
/*Latest UI Ends Here*/


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.skiptranslate .goog-te-gadget {
  margin-right: 98px !important;
}

/* google traslator */
/* Hide the top toolbar */
.goog-te-banner-frame .skiptranslate {
  display: none !important;
}

/* #google_translate_element{
  margin-right: 98px !important;
} */

body {
  top: 0px !important;
}

/* Hide the "Powered by Google" branding */
.goog-logo-link {
  display: none !important;
}

.explanationSection {
  display: none !important
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
}

/* Hide the Google Translate loader */
div[id^='goog-gt-'] {
  display: none !important;
}

/* Optionally, you can hide other unwanted elements like the iframe border */
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

#goog-gt-tt {
  display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf .VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none !important;
  left: -100px !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  left: -100px !important;
  top: -14px;
}

/* Adjust the height and font size of the dropdown items */
#google_translate_element .goog-te-menu-value span {
  font-size: 0.8em;
  /* Smaller font size */
  line-height: 1em;
  /* Adjust line height */
  display: none !important;
}


/* Optionally, adjust the icon size */
.goog-te-gadget-icon {
  width: 12px;
  /* Adjust the width of the icon */
  height: 12px;
  /* Adjust the height of the icon */
  background-size: 12px 12px;
  /* Adjust the background size */
  display: inline-block;
  vertical-align: middle;
  display: none !important;
}

/* Hide the duplicate "Select Language" */
#google_translate_element .goog-te-gadget-simple .goog-te-menu-value span:first-child {
  display: none;
}

/* Hide the "Powered by Google" text */
#google_translate_element .goog-logo-link {
  display: none !important;
}

.goog-te-spinner-pos {
  display: none !important;
}

/* Hide the additional "Select Language" dropdown */
#google_translate_element .goog-te-gadget-simple .goog-te-menu-value span:nth-child(3) {
  display: none !important;
}

/* Adjust the overall appearance of the widget */
#google_translate_element select.goog-te-combo {
  font-size: 0.8em;
  /* Adjust the font size */
  padding: 2px;
  /* Adjust padding */
  height: auto;
  /* Adjust height */
  margin-right: auto;
  line-height: normal;
  /* Adjust line height */
  width: auto;
  /* Adjust the width */
  border-radius: 4px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
}

/* Hide the additional Google Translate branding */
.goog-te-gadget {
  color: transparent !important;
  font-size: 0.8em;
  line-height: normal;
  width: auto;
}

.goog-te-gadget-simple {
  padding: 0;
  margin: 0;
  display: inline-block;
}

.goog-te-combo {
  display: none !important;
}

#google_translate_element {
  width: 130px !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.d-none {
  display: none;
}

.fabBtn {
  position: fixed;
  right: 40px;
  bottom: 32px;
  z-index: 1;
}

.fabBtn img {
  width: 54px;
}

.fontBlack {
  color: #000000 !important;
}

.commonBgCard .fontBlack .MuiInputBase-input {
  color: #000000 !important;
}
.alertLogo
{
  max-width: 200px;
}
.d-flex
{
  display: flex;
}

@media only screen and (min-width: 280px) and (max-width: 600px) {
  .actionsBar {
    margin-top: 42px;
    position: relative;
    left: -30px;
  }

  .actionsBar .primaryFillBtn,
  .actionsBar .primaryLineBtn {
    width: 60px !important;
    font-size: 12px;
  }

  .landingPageNavBar {
    height: 100px !important;
    padding: 0px !important;
  }

  #google_translate_element {
    position: absolute;
    top: 26px;
    right: 20px;
  }

  .headerItemsCenter {
    justify-content: center;
    /* margin-top: -10px !important;
    margin-bottom: 0px !important; */
  }

  .landingPageNavBar .actionsBar #google_translate_element {
    top: -34px;
    right: -20px;
  }

  .fabBtn {
    right: 30px;
    bottom: 62px;
  }

  .fabBtn img {
    width: 60px;
  }
}