.bannerTitle
{
  font-size: 14px;
  /* color: #407bff; */
}
.bannerContent
{
  position: absolute;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  height: 80vh;
}
.heroSection
{
  background-image: url(../../assets/images/landingPage/banner.jpg);
}
.heroSectionBody
{
  height: calc(100% - 64px);
}
.heroSectionBody .bannerTitle
{
  font-size: 60px !important;
}
.lotteryResult
{
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0px 3px 12px 0px rgb(121 112 112 / 17%);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
}
.lotteryResult .gameDate
{
  color: #ffffff;
}
.announcementsSection 
{    
  /* background-color: #407bff; */
  padding: 18px;
  color: #ffffff;
  position: absolute;
  bottom: 0px;
}
.explanationSection .commonDescription
{
  font-size: 14px;
  line-height: 30px;
} 
.explanationImage 
{
  padding: 32px;
  height: 480px;
  position: relative;
}
.explanationImage::before
{
  content: "";
  width: 100px;
  height: 100px;
  background-color: #407bff5e;
  border-radius: 50px;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 0;
}
.explanationImage img
{
  box-shadow: -8px 24px 24px 0px rgba(242,242,242,1);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.animate_line 
{
  animation: left_right 1.5s ease-in infinite alternate-reverse;
}
.overlayBanner
{
  width: 100%;
  height: 90vh;
  background-color: #212121bb;
  position: absolute;
  top: 0px;
  left: 0px;
}
.landingPage .bannerContent .bannerTitle
{
    color: #ffffff;
}
.landingPage .acceptedPayments .commonListItem
{
  display: flex;
  flex-direction: column;
  float: left;
  width: 120px;
  margin: 12px;
  padding: 0px;
}
.acceptedPayments .landingPageHeading 
{
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 24px;
  text-decoration: underline;
}
.landingPage .heroSection
{
  height: 90vh;
  border-radius: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.landingPage .pageViewBody
{
  padding: 0px;
  height: calc(100% - 68px);
}
.landingPageNavBar
{
    /* background-color: #ffffff; */
    -webkit-box-shadow: 0px 3px 12px 0px rgb(121 112 112 / 17%);
    -moz-box-shadow: 0px 3px 12px 0px rgb(121 112 112 / 17%);
    box-shadow: 0px 3px 12px 0px rgb(121 112 112 / 17%);
    height: 70px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
}
.referandearnSection .commonBgCard
{
    background-image: url(../../assets/images/landingPage/referEarn1.png);
    height: 360px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.referEarnTitle
{
  font-size: 40px !important;
    font-weight: 600 !important;
    margin-bottom: 32px !important;
    padding-left: 36px;
}
.referEarnBtn
{
  max-width: max-content;
  margin-left: 36px !important;
  font-size: 22px !important;
}
.thaiLottery
{
  background-color: #000133;
}
.bangkokLottery
{
  background-color: #820000;
}
.landingPageFooter
{
  padding: 42px 0px;
  /* background-color: #010101; */
  color: #ffffff;
}
.numberCircleGroup
{
  display: flex;
  justify-content: center;
}
.numberCircle
{
  width: 32px;
  height: 32px;
  display: block;
  background-color: #ffffff;
  color: #212121;
  border-radius: 16px;
  font-weight: 700;
  line-height: 28px;
  float: left;
  margin: 12px 8px 28px;
  text-align: center;
}
.allResultsList
{
  text-decoration: underline !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin-bottom: 16px !important;
}
.footerList
{
  display: flex;
}
.footerList a
{
  padding: 4px 24px 12px 0px !important;
  width: auto;
}
.footerList .boxTitle
{
  font-size: 20px;
  font-weight: 500;
}
.footerList a span
{
  font-size: 13px;
  color: #b4b4b4;
  font-weight: 500;
}
.footerList a span:hover
{
  color: #407bff;
}
.landingPageFooter .socialMediaLinks
{
  justify-content: space-between;
  margin: 20px 0px;
}
.appDownloadBtn
{
  padding: 0px !important;
  border: 0px !important;
}
.nextDraw
{
  padding-top: 12px;
  background-color: #0adab9;
}
.nextDraw .prizeType
{
  color: #ffffff;
  font-weight: 700;
}
.nextDraw p
{
  color: #ffffff;
  font-size: 14px;
  font-weight: 400 !important;
}
.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px 10px;
  border-radius: 10px;
  color: white;
}

.time-segment {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 0px;
  width: 50px;
  margin: 4px;
  color: #212121;
  height: 60px;
}

.time-value {
  font-size: 24px;
  font-weight: bold;
}

.time-label {
  font-size: 14px;
  margin-top: 5px;
}
.footerLogo
{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 24px;
}
.HeaderLogo
{
  width: 160px;
}

@media only screen and (min-width: 280px) and (max-width: 600px)
{
  .referandearnSection .commonBgCard
  {
    background-image: none;
    background-color: #e3ebff;
  }
  .referEarnTitle
  {
    font-size: 32px !important;
    padding-left: 0px;
  }
  .referEarnBtn 
  {
    margin-left: 0px !important;
    font-size: 16px !important;
  }
}

/* General styling for mobile screens */
@media only screen and (max-width: 600px) {
  .landingPageNavBar {
    padding: 10px;
  }

  .HeaderLogo {
    text-align: center;
    margin-bottom: 10px;
  }

  .HeaderLogo img {
    width: 150px; /* Adjust logo size for mobile */
  }

  .floatR {
    flex-direction: column; /* Stack the buttons and language dropdown */
    align-items: center;
  }

  .primaryFillBtn,
  .primaryLineBtn {
    width: 100%; /* Make buttons full-width */
    margin-bottom: 10px; /* Add space between buttons */
  }

  /* Adjust the Google Translate dropdown */
  #google_translate_element {
    margin-bottom: 10px; /* Space below the dropdown */
    width: 100%;
    text-align: center;
  }
}
