.commonTwoExtraModalBox .MuiPaper-root
{   
    max-width: 100% !important;
}   
.commonTwoExtraModalBox .commonExtraModalBox 
{
    margin-top: 0px;
}
@media only screen and (min-width: 1600px) and (max-width: 2000px) 
{
    .commonModal .commonModalBox.commonExtraModalBox 
    {
        width: 1200px;
        max-width: 1200px !important;
    }
    .commonNotePoints
    {
        line-height: 22px;
    }
}