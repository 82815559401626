.alertModel .MuiDialog-paper {
    margin: 0px;
    padding: 32px 24px;
    text-align: center;
}

.alertIcon svg {
    width: 40px;
    height: 40px;
}

.errorColor {
    color: #F70000;
}

.warningColor {
    color: #F7C500;
}

.successColor {
    color: #139F4B;
}

.infoColor {
    color: #00C3EC;
}

.alertDialogDescription {
    font-size: 16px !important;
    margin-top: 12px !important;
    margin-bottom: 12px;
    padding: 0px !important;
}

.alertDialogDescription .content {
    color: #212121 !important;
}

.alertDialogDescription .notifyWhatsapp {
    color: #212121 !important;
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    margin-top: 15px;
}

.alertFooter {
    justify-content: center !important;
}

.alertAction {}