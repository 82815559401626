.changePassworgHead
{
    display: flex;
    justify-content: center;
    color: #ffffff;
    margin-bottom: 24px;
}
.changePassworgHead svg
{
    font-size: 52px;
}