.firstPrizeBox
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gameDate
{
    font-size: 24px !important;
    margin: 12px 0px 18px !important;
    color: #ff0000;
}
.firstPrizeBox .numberCircle
{
    background-color: #407bff;
    color: #ffffff;
}
.prizeType
{
    font-weight: 600;
    font-size: 18px;
    color: #f4f4f4;
}
.secondPrizeBox .numberCircle
{
    background-color: #fa0;
    color: #ffffff;
}
.thirdPrizeBox .numberCircle
{
    background-color: #ff0000;
    color: #ffffff;
}
.resultHistoryCard .numberCircle
{
    margin-bottom: 12px;
}
.resultHistoryCard 
{
    position: relative;
}
.prizeCup 
{
    width: 60px;
    position: absolute;
    left: -12px;
    top: 42px;
}