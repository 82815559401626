.offerImage {
    height: 200px;
    padding: 0px;
    border: 1px solid #eeeeee;
    border-radius: 0px;
    background-color: #ffffff;
}

.offersPage .commonCard {
    min-height: 332px;
}

/* .offersPage .offerImage img {
    object-fit: fill !important;
} */
 .offerImage img {
    object-fit: contain !important;
}

/* .offerTitle
{
    font-size: 14px !important;
    font-weight: 600 !important;
    padding-top: 16px;
}
.offerDescription
{
    font-size: 12px !important;
    padding-top: 6px;
} */