.removeRowBtn {
    position: relative;
    top: 24px;
    background-color: transparent !important;
    text-align: left;
    justify-content: flex-start !important;
}

.numbersPlayedTable {
    border-radius: 0px !important;
}

.numbersPlayedTable th {
    font-weight: 600 !important;
}
.numbersPlayedTable td {
    text-align: left;
}
.gameSucess .commonLabel
{
    text-align: left;
}