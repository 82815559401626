.footerSectionTitle
{
    color: #ffffff !important;
    font-weight: 700;
}
@media only screen and (min-width: 280px) and (max-width: 600px)
{
    .footerList 
    {
        flex-direction: column;
    }
    .footerBodyPart
    {
        flex-direction: column !important;
    }    
}